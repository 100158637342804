import React, {PureComponent, ReactNode} from "react";

import Logo from "../../../../public/assets/images/DB.svg";

export class DBConnect extends PureComponent {

    render(): ReactNode {

        return (
            <div className={'w-full md:w-1/2 my-0 mx-auto'}>
                <picture>
                    <img
                        loading={'lazy'}
                        src={Logo.src}
                        alt="DB Connect"
                        className={'mx-auto'}
                        height={'49'}
                        width={'69'}
                    />
                </picture>
            </div>
        );
    }
}