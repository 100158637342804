import React, {PureComponent, ReactNode} from "react";
import {DBConnect} from "./PartnerIcons/DBConnect";
import {Arval} from "./PartnerIcons/Arval";
import {Carglass} from "./PartnerIcons/Carglass";
import {MobileDE} from "./PartnerIcons/MobileDE";
import {ZukunftsWerkstatt} from "./PartnerIcons/ZukunftsWerkstatt";
import {Copart} from "./PartnerIcons/Copart";

export type PartnerIconCollectionProps = {
    headline: string,
};

export class PartnerIconCollection extends PureComponent<PartnerIconCollectionProps> {

    render(): ReactNode {
        const {
            headline,
        } = this.props

        return (
            <div className={`partnerIconCollection px-6 md:px-12 pb-12 md:pb-24`}>
                <h2 className={`text-2xl leading-8 md:text-3xl md:leading-9 font-semibold font-serif text-gray-900 pb-6`}>{headline}</h2>
                <div className={'grid grid-cols-12 items-center gap-x-0.5 gap-y-0.5 bg-white px-6 md:px-0'}>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <DBConnect/>
                    </div>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Arval/>
                    </div>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <ZukunftsWerkstatt/>
                    </div>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <MobileDE/>
                    </div>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Copart/>
                    </div>
                    <div className={'partnerIconCollection__IconContainer flex items-center w-full h-full col-span-6 md:col-span-4 bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Carglass/>
                    </div>
                </div>
            </div>
        );
    }
}