import React, {PureComponent, ReactNode} from "react";

import Logo from "../../../../public/assets/images/carglass.svg";

export class Carglass extends PureComponent {

    render(): ReactNode {

        return (
            <div className={'w-full md:w-1/2 my-0 mx-auto'}>
                <picture>
                    <img
                        loading={'lazy'}
                        src={Logo.src}
                        alt="Carglass"
                        width={'200'}
                        height={'48'}
                    />
                </picture>
            </div>
        );
    }
}